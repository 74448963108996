<template>
  <div>
    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i> Portfolio Information</h2>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted"></p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <FormGroup id="name" v-model="form.name" :disabled="disabled" :error="error['name']" label="Name" type="text" @input="$emit('input', form)" />
      </div>
    </div>
  </div>
</template>
<script>
import Form from './Form';

export default {
  name: 'PortfolioForm',
  extends: Form,
  props: {
    value: {
      type: Object,
      required: true
    },
    error: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        name: ''
      }
    };
  },
  watch: {
    value(val) {
      if (val._id !== this.form._id) {
        Object.keys(this.form).forEach(key => {
          if (val[key]) this.$set(this.form, key, val[key]);
        });
      }
    },
    form(form) {
      if (this.form._id === form._id) {
        this.$emit('input', this.form);
      }
    }
  },
  async mounted() {
    this.form = {
      ...this.value
    };
  }
};
</script>
